import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import logo from '../../images/sm-light-logo.svg';
import Badge from '../../molecules/Badge/Badge';
import { colors } from '../../styles/atoms/colors';
import Link from '../../utils/Link';
import Img from '../../utils/OptimizedImage';
import getReadTimeLabel from '../../utils/getReadTimeLabel';

const FeaturedBlog = ({ blog }) => {
  const wordCount = JSON.parse(blog?.contentWordCounter).words;
  return (
    <Container className="featured-blog-block">
      <Link to={`/blog/${blog?.slug}`} className="overlay-link" />
      <Row className="mx-0 featured-blog-container">
        <Col className="px-0 image-wrapper">
          {blog?.image ? (
            <Img
              className="w-100 h-100"
              image={blog?.image?.gatsbyImageData}
              alt={blog?.internalName}
              loading="lazy"
            />
          ) : (
            <div className="w-100 h-100 d-flex align-items-center justify-content-center py-5 featured-blog-bg">
              <img src={logo} alt="Shopmonkey Logo" loading="lazy" />
            </div>
          )}
        </Col>
        <div className="px-0 bg-white content-wrapper">
          <div className="blog-content">
            <div className="blog-category">
              <Badge
                size="sm"
                badgeColor={colors.purple[100]}
                textColor={colors.purple[900]}
              >
                Featured Blog
              </Badge>
            </div>
            <h3 className="blog-title">{blog?.title}</h3>
            <div className="blog-desc">
              {blog?.excerpt || blog?.metaTags?.description}
            </div>
            <div className="publish-date">
              <span>{getReadTimeLabel(wordCount)}</span>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default FeaturedBlog;
